body{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
  }

.footer .footer-bottom{
    background-color: #202020;
    padding-top: 30px;
    padding-bottom: 30px;
}

.footer .footer-bottom ul li a {
    line-height: 30px;
    color: #fff;
    position: relative;
    margin-left: 23px;
    display: block;
    text-decoration: none;
    font-family: 'Roboto Condensed', sans-serif;
}

.footer-title{
    color: #fff;
    font-size: 1.4rem;
    margin-bottom: 20px;
}

.footer-title a{
    color: #fff;
    font-size: 1.4rem;
    text-transform: none;
}

.flex-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-items: flex-start;
}

.flex-item{
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
}

.footer{
    margin-top: auto;
    flex: 0 0 auto; 

}