header{
    background-color: #487922;
}

.top-bar{
    padding: 0;
    font-size: 12px;
    border-bottom: .4px solid #3b621c;
    min-height: 40px;
}

.main-header{
    padding: 20px 0 25px;
}

.site-title{
    color: #fff;
}