.block{
    padding: 20px 15px 20px 20px;
    border: 1px solid #747474;
    margin: 20px;
}

.block div{
    margin-bottom: 20px;
}

.block .currency-in, .block .currency-out{
    font-size: 1.3rem;
    text-align: right;
}

.flex-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-items: flex-start;
}

.flex-item{
    order: 0;
    flex: 1 1 auto;
    align-self: auto;
}

.currency-name{
    font-family: Oswald;
    display: 1.3rem;
    font-weight: bold;
}