body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.calc-res{
  font-size: 1.2em;
}

.calc-res li{
  margin: 10px 0;
}

.footer .footer-bottom{
  background-color: #202020;
  padding-top: 30px;
  padding-bottom: 30px;
}

.footer .footer-bottom ul li a {
  line-height: 30px;
  color: #fff;
  position: relative;
  margin-left: 23px;
  display: block;
  text-decoration: none;
  font-family: 'Roboto Condensed', sans-serif;
}

.footer-title{
  color: #fff;
  font-size: 1.4em;
  text-decoration: none;
}